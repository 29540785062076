import React from 'react'

function Faq() {
  return (
    <div>
      FAQ
    </div>
  )
}

export default Faq
