import React from 'react'

function Products() {
  return (
    <div>
      Products
    </div>
  )
}

export default Products
